@import '../../../pages/App/sass/variables.scss';

.sidebar {
  max-width: 250px;
  width: 100%;
  background-color: $buttonColor;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 32px;
  position: fixed;
  left: 25px;
  height: calc( 100vh - 172px );
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media only screen and (max-width: $screen-xs) {
    left: -500px;
    z-index: 27;
    height: 100vh;
    top: 0;
    transition: left 0.5s ease;
    border-radius: 0;
    &.active {
      left: 0;
    }
  }

  @media only screen and (max-width: $screen-xxs) {
    border-radius: 0;
  }

  .sideBarTitle {
    display: flex;
    align-items: center;
    margin: 35px 20px 12px;
  }

  .sidebarInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:after {
      content: "";
      position: absolute;
      left: -22px;
      bottom: -16px;
      width: 221px;
      height: 1px;
      background-color: $defaultWhite;
    }

    .sidebarInfoDescription {
      display: flex;
      flex-direction: column;
      gap: inherit;
    }
  }

  .points {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 41px;
  }
}
