@import '../../../pages/App/sass/variables';

.container {
  background-color: $blueChat;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 25%;
  min-width: 270px;
  height: 100px;
  z-index: 100;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  transition: opacity 1s ease-in-out;
  opacity: 1;

  .image {
    margin-right: 20px;
    border-radius: 100px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
      color: $whiteGrey;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
