@import '../../../pages/App/sass/variables.scss';

.container {
  width: 734px;
  min-height: 438px;
  background: $milkyColor;
  border-radius: 22px;
  padding: 52px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $screen-xs) {
    width: 100%;
    padding: 40px 20px;
    min-height: fit-content;
  }
}
