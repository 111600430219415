@import '../../pages/App/sass/variables.scss';

.container {
  width: 100%;
  min-height: 100vh;
  padding: 24px;

  &:not(.elementsHidden) {
    padding: 148px 24px 24px 275px;
  }

  @media #{$tablets} {
    padding: 0;

    &:not(.elementsHidden) {
      padding: 108px 0 0 0;
    }
  }
  @media #{$phones} {
    padding: 0;
  }
}

.containerBackground {
  background-color: $whiteGrey;
}

.whiteBackground {
  background-color: $defaultWhite;
}

.wrapper {
  width: 100%;
  display: flex;

  @media #{$tablets-big} {
    min-height: 100vh;
  }
  min-height: calc(100vh - 250px);
}

.main {
  width: 100%;
  position: relative;
  z-index: 1;

  @media #{$tablets} {
    width: 100%;
  }
}

.forms {
  width: 100%;
}
