@import '../../../pages/App/sass/variables.scss';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    width: fit-content;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $buttonColor;
    font-family: $main-font-family;

    &:hover {
      opacity: .8;
    }
  }
}

.subItemSidebar {
  padding: 17px 0 17px 36px;
  transition: all 0.3s ease;
  cursor: pointer;

  a {
    display: flex;
    gap: 12px;

    svg {
      g {
        path {
          fill: $defaultIconColor;
        }
      }
    }
  }

  &:hover {
    background-color: $buttonColor;

    svg {
      g {
        path {
          fill: $defaultWhite;
        }
      }
    }

    span {
      color: $defaultWhite;
    }
  }
}

.timeTableEvent {
  background-color: transparent;
  width: max-content;
  padding: 0;

  svg {
    color: $timerText;
  }

  &:hover {
    background-color: transparent;
  }
}

.card {
  min-width: 48%;
  border: 1px solid $defaultGray;
  border-radius: 24px;
  position: relative;
  padding: 31px 16px 19px 25px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media only screen and (max-width: 1430px) {
    min-width: 100%;
  }
}

.forgotPassword {
  justify-content: flex-end;
  margin: 12px 0 32px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;

  }

  @media only screen and (max-width: $screen-xxs) {
    margin-bottom: 24px;
  }
}

.sidebarItem_title, .sidebarItem_title_light {
  a {
    width: auto;
    font-weight: 450;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0;
    text-decoration: none;

    &:hover {
      color: $defaultBlue;
    }
  }

  &_light {
    a {
      color: $defaultWhite;

      &:hover {
        color: $greyText;
      }
    }
  }
}

.sidebarItem_title_active, .sidebarItem_title_active_light {
  a {
    width: auto;
    font-weight: 450;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0;
    text-decoration: none;
  }
}

.sidebarItem_title_active {
  a {
    color: $defaultBlue;

    &:hover {
      color: $defaultBlue;
    }
  }
}

.sidebarItem_title_active_light {
  a {
    color: $greyText;

    &:hover {
      color: $greyText;

    }
  }
}

.successButton {
  justify-content: center;

  a {
    color: $defaultWhite;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    width: 100%;
    height: 70px;
    background: $buttonColor;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    color: $defaultHoverColor;
  }
}

.returnToLogin {
  margin-top: 16px;

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: $defaultBlue;
    text-decoration: none;
  }

  &:hover {
    color: $defaultHoverColor;
  }
}

.returnToLoginSuccess {
  @extend .returnToLogin;
  width: auto;
}

.viewProfile {
  width: max-content;
  background: $buttonColor;
  border-radius: 40px;

  a {
    padding: 14px 32px;
    color: $defaultWhite;
  }
}

.ghostButton {
  width: auto;
  padding: 13px 32px;
  background-color: transparent;
  border: 1px solid $buttonColor;
  border-radius: 40px;
  transition: 0.2s ease-in-out;

  a {
    color: $buttonColor;
  }

  &:hover {
    a {
      color: $defaultWhite;
    }

    background-color: $buttonColor;
  }
}

.sidebarDashboard {
  div {
    display: flex;
    gap: 12px;
  }
}

.courseDashboardPrimary, .courseDashboardPrimaryMobile {
  min-width: fit-content;
  max-width: fit-content;

  a {
    padding: 14px 32px;
    border-radius: 40px;
    background-color: $buttonColor;
    color: $defaultWhite;
  }
}

@media #{$tablets-big} {
  .courseDashboardPrimary {
    display: none;
  }

  .courseDashboardPrimaryMobile {
    display: block;
  }
}

@media only screen and (min-width: $screen-sm) {
  .courseDashboardPrimaryMobile {
    display: none;
  }
}

.quickLinkButtons {
  cursor: pointer;
  display: block;
  width: inherit;

  &:nth-child(2) {
    svg {
      fill: $defaultBlue;
    }
  }

  &:nth-child(3) {
    svg {
      fill: $goldenColor;
    }
  }
}
