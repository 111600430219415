@import '../../../pages/App/sass/variables.scss';

.headerWrapper {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: 24px;
  z-index: 25;
  border-bottom-right-radius: 63px;
  
  @media #{$tablets} {
    padding: 0;
  }
}

.header {
  box-shadow: $shadowBlock;
  border-radius: 20px;
  padding: 22px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $defaultWhite;
  
  .logo { 
    width: 163px;
    height: auto;
  }

  @media #{$tablets} {
    padding: 18px 16px;
    border-radius: 0;
    top: 0;
    left: 0;
    width: 100vw;
  }

  @media only screen and (max-width: $screen-xxs) {
    
    margin-bottom: 0;
    .logo {
      width: 93px;
      height: auto;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 18px;
  }
}
