@import '../../../pages/App/sass/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.profileStep {
  width: 100%;
  justify-content: space-between;

  @media #{$tablets-big} {
    width: 100%;
  }
}
