@import '../../../pages/App/sass/variables.scss';

.logout {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: 450;
  font-size: 16px;
  line-height: 120%;
  padding-top: 20px;
  margin-top: auto;
  
  &_iconWrapper {
    width: 30px;
    height: 30px;
    background-color: $seekBarColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    margin-bottom: 8px;
  }
}

.headerLogout {
  flex-direction: row;
  padding-top: 0;
  font-family: $main-font-family;

  .logout_iconWrapper {
    margin-bottom: 0;
    margin-right: 5px;
  }

  @media #{$tablets} {
    display: none;
  }
}