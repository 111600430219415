  @import '../../../pages/App/sass/variables.scss';

  .sidebar {
    min-width: 243px;
    max-width: 250px;
    box-shadow: $shadowBlock;
    border-radius: 10px;
    padding: 28px 0 30px;
    font-family: $main-font-family;
    display: flex;
    flex-direction: column;
    flex-basis: 243px;
    position: fixed;
    left: 25px;
    height: calc( 100vh - 172px );
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    @media only screen and (max-width: $screen-xs) {
      left: -500px;
      z-index: 27;
      background-color: $defaultWhite;
      top: 0;
      height: 100vh;
      transition: left 0.5s ease;
      border-radius: 0;
      &.active {
        left: 0;
      }
    }

    @media only screen and (max-width: $screen-xxs) {
      border-radius: 0;
    }

    .profileInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      color: $defaultBlack;
      .userPicWrapper {
        width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $seekBarColor;
        border-radius: 50%;
        img {
          border-radius: 50%;
        }
      }
    }

    .points {
     display: flex;
     flex-direction: column;
    }
  }
