$screen-min: 320px;
$screen-min-s: 374px;
$screen-xxs: 576px;
$screen-xs: 768px;
$screen-sm: 1024px;
$screen-md: 1274px;
$screen-lg: 1440px;
$screen-max: 1920px;

$retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
$phones-xs:'only screen and (max-width: #{$screen-min-s})';
$phones: 'only screen and (max-width: #{$screen-xxs})';
$tablets: 'only screen and (max-width: #{$screen-xs})';
$tablets-big: 'only screen and (max-width: #{$screen-sm})';
$desktop: 'only screen and (max-width: #{$screen-md})';
$full-hd: 'only screen and (max-width: #{$screen-lg})';

// Colors
$defaultWhite: #FFFFFF;
$defaultText: #1D1D1D;
$greyText: #DADADA;
$greyScroll: #D9D9D9;
$seekBarColor: #BB945C;
$defaultBlack: #181C2A;
$buttonColor: #2D4960;
$defaultBlue: #204FFF;
$whiteGrey: #F5F5F7;
$milkyColor: #F9F9F9;
$defaultRed: #EC1E32;
$defaultOrange: #FDB717;
$goldenColor: #EFA600;
$defaultMagenta: #F26EA7;
$lightPink: #F4E2FF;
$purple: #B313FE;
$purpleChat: #700ABF;
$blueChat: #4F73B8;
$defaultLightOlive: #9ACAB3;
$defaultGray: #C9C9C9;
$defaultLightGrey: #E7E1E1;
$placeholder: #2D49604D;
$lightGrey: #F1EEE9;
$statusGrey: #8D9BA8;
$defaultHoverColor: #1E3649;
$defaultActiveColor: #152532;
$taskBlue: #E1E8F0;
$descriptionText: #7D8AAC;
$timerText: #4FB86C;
$dirtyGray: #93A1AD;
$bannerDescription: #83858F;
$defaultIconColor: #93A1AD;

$darkGrey: rgba(45, 73, 96, 0.5);
$darkShadow: rgba(0, 0, 0, 0.22);
$shadowBlock: 0px 11px 22px rgba(0, 0, 0, 0.1);
$lightGreen: rgba(79, 184, 108, 0.15);
$calendarButtonColor: rgba(187, 148, 92, 0.17);
$calendarActiveButtonColor: rgba(187, 148, 92, 0.3);

//fonts
$main-font-family: 'Circular Std', sans-serif;
$font-family-Italic: 'Circular Std Italic', sans-serif;
$font-family-Medium: 'Circular Std Medium', sans-serif;
$font-family-Medium-Italic: 'Circular Std Medium Italic', sans-serif;
$font-family-Std-Bold: 'Circular Std Bold', sans-serif;
$font-family-Std-Bold-Italic: 'Circular Std Bold Italic', sans-serif;
$font-family-Std-Black: 'Circular Std Black', sans-serif;
$font-family-Std-Black-Italic: 'Circular Std Black Italic', sans-serif;
