@import '../../../pages/App/sass/variables.scss';

.notification {
  width: calc(100vw - 320px);
  max-width: 1500px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  box-shadow: 0 4px 60px $darkShadow;
  border-radius: 8px;

  &.success {
    background-color: $timerText;
  }

  &.error {
    background-color: $defaultRed;
  }

  .errorIcon {
    fill: $defaultWhite;
  }

  @media #{$tablets} {
    width: calc(100vw - 50px);
  }

  .notificationLeft {
    display: flex;
    align-items: center;
    gap: 14px;

    svg {
      max-width: 26px;
      width: 100%;
    }
  }

  .notificationRight {
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      width: 38px;
      height: 1px;
      background-color: $defaultWhite;
      transform: rotate(90deg);
      right: 30px;
    }
  }
}
