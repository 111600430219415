@import '../../../pages/App/sass/variables.scss';

.wrapperMenu {
  width: 100%;
  display: flex;
  flex-direction: column;

  &.disabled {
    cursor: not-allowed;
  }
}

.sidebar_item {
  padding: 10px 0 12px 36px;
  display: flex;
  align-items: center;
  font-weight: 450;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .itemIconWrapper svg {
    margin-right: 12px;
    fill: $defaultIconColor;

    &:hover {
      fill: $defaultIconColor;
    }
  }

  .activeItem {
    svg {
      fill: $defaultBlue;
    }
  }

  &:hover {
    color: $defaultBlue;

    svg {
      fill: $defaultBlue;
    }
  }

  .arrowIcon {
    margin-left: 12px;
    transition: all 0.3s ease;
  }

  &.active .arrowIcon {
    transform: rotate(180deg);
  }

  &_light {
    padding: 10px 0 12px 29px;

    .itemIconWrapper svg {
      fill: $defaultWhite;
    }
  }
}


.subItemList {
  background-color: $whiteGrey;
  max-height: 0;
  overflow: auto;
  transition: max-height 0.5s ease;

  &.active {
    max-height: 230px;
    height: auto;
  }

  .subItem {
    padding: 17px 0 17px 36px;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:hover {
      background-color: $buttonColor;

      span {
        color: $defaultWhite;
      }
    }
  }
}
