@import '../../App/sass/variables.scss';

.loginWrapper {
  width: 100%;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $screen-xs) {
    padding: 0 16px;
  }

  .containerInputs {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
  }
}