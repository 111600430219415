@import '../../../pages/App/sass/variables.scss';

.notifications {
  position: fixed;
  bottom: 24px;
  left: 290px;
  z-index: 10000;

  @media #{$tablets-big} {
    left: 265px;
  }

  @media #{$tablets} {
    left: 25px;
  }
}
