@import '../../../pages/App/sass/variables.scss';

.container {
  width: 100%;
  display: flex;
}

.inputClass {
  width: 100% !important;
  height: 56px !important;
  padding-left: 70px !important;
  border-radius: 6px !important;
  background: $defaultWhite !important;
  border: 1px solid $defaultGray !important;
  font-family: $main-font-family !important;
  font-size: 16px !important;
  &::placeholder {
    display: flex;
    font-size: 16px;
    color: $darkGrey;
    font-weight: 450;
    line-height: 140%;
    font-style: normal;
    align-items: center;
    font-family: $main-font-family;
  }
  &:focus {
    outline: none;
  }
}

.buttonClass {
  border-right: 1px solid transparent !important;
  background-color: $defaultWhite !important;
  padding-left: 21px !important;

  div {
    background-color: transparent;
  }
}

