@import '../../../pages/App/sass/variables.scss';

.container {
  position: relative;
  input {
    width: 100%;
    height: 70px;
    padding: 24px;
    border-radius: 6px;
    background: $defaultWhite;
    border: 1px solid $defaultGray;
    font-family: $main-font-family;
    font-size: 16px;
    &::placeholder {
      display: flex;
      font-size: 16px;
      color: $darkGrey;
      font-weight: 450;
      line-height: 140%;
      font-style: normal;
      align-items: center;
      font-family: $main-font-family;
    }
    &:focus {
      outline: none;
    }
  }

  @media only screen and (max-width: $screen-xxs) {
    input {
      height: fit-content;
      padding: 20px;
      font-size: 14px;
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: $screen-min-s) {
    input {
      padding: 16px;
    }
  }
}