@import '../../../pages/App/sass/variables.scss';

.container {
  width: 100%;
  position: relative;

  .icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    right: 18px;
  }

  input {
    width: 100%;
    height: 70px;
    padding: 24px;
    border-radius: 6px;
    background: $defaultWhite;
    border: 1px solid $defaultGray;
    font-family: $main-font-family;
    font-size: 16px;


    &::placeholder {
      display: flex;
      font-size: 16px;
      color: $placeholder;
      font-weight: 450;
      line-height: 140%;
      font-style: normal;
      align-items: center;
      font-family: $main-font-family;
    }

    &:focus {
      outline: none;
    }
  }

  @media only screen and (max-width: $screen-xxs) {
    input {
      height: fit-content;
      padding: 20px;
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: $screen-min-s) {
    input {
      padding: 16px;
    }
  }
}

.inputError {
  input {
    border: 1px solid $defaultRed;
  }
}

.referInput {
  width: 70%;

  input {
    height: 44px;
    border: 1px solid transparent;
    padding: 0;
    font-size: 14px;

    &::placeholder {
      font-weight: 450;
      font-size: 14px;
      line-height: 150%;
    }
  }

  @media only screen and (max-width: $screen-xxs) {
    width: 100%;
    input {
      height: 48px;
      border: 1px solid $defaultGray;
      border-radius: 6px;
      padding: 6px 9px 6px 16px;
    }
  }
}

.inputProfile {
  input {
    width: 100%;
    height: 56px;
  }
}

.inputProfileDisable {
  @extend .inputProfile;

  input {
    background: $lightGrey;
  }
}

.errorWrapper {
  input {
    border: 1px solid $defaultRed;
  }

  .error {
    font-size: 12px;
    color: $defaultRed;
    display: block;
    font-family: $main-font-family;
    position: absolute;
  }
}

.attendanceComment {
  input {
    height: 48px;
  }
}
